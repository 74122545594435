<template>
  <div>
    <div>
      <div class="pa-0 ma-0">
        <v-card
          flat
          style="background-color: var(--v-background-base) !important"
          class="pa-0 ma-0"
        >
          <v-toolbar
            color="greyBase"
            dark
            :extended="!$vuetify.breakpoint.mobile"
            flat
            extension-height="55"
            class="pa-0 ma-0"
          >
            <v-toolbar-title>
              <b class="mr-3">Stock File Upload</b>
            </v-toolbar-title>
          </v-toolbar>

          <v-card
            class="mx-auto"
            :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'"
            :style="{
              'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
              'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
            }"
          >
            <v-card-text
              :style="{
                height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                'overflow-y': 'auto',
              }"
            >
              <v-row>
                <v-col cols="12" sm="4">
                  <v-card
                    flat
                    style="
                      border-radius: 20px;
                      background-color: var(--v-toolbar-lighten1) !important;
                    "
                    :loading="loading"
                  >
                    <v-card-text>
                      <v-subheader
                        >Folders <v-spacer></v-spacer
                        ><v-btn @click="listObjects()" icon
                          ><v-icon>refresh</v-icon></v-btn
                        ></v-subheader
                      >
                      <v-divider></v-divider>
                      <v-list dense>
                        <v-treeview
                          :active.sync="active"
                          :items="directoryListing"
                          :load-children="getSubfolders"
                          :open.sync="open"
                          hoverable
                          activatable
                          color="blue"
                          item-key="id"
                          transition
                          return-object
                        >
                          <template v-slot:prepend="{ item }">
                            <v-icon
                              v-if="item.children && item.children.length > 0"
                              color="amber"
                            >
                              folder
                            </v-icon>
                          </template>
                          <!-- <template v-slot:append="{ item }">
                          <v-btn icon @click="addFolder(item)" v-if="selectedFolder && selectedFolder.id == item.id">
                            <v-icon color="grey"> add_circle_outline </v-icon>
                          </v-btn>
                        </template> -->
                          <template v-slot:label="{ item }">
                            <v-chip
                              @contextmenu.prevent="viewMenu($event, item)"
                            >
                              <div>{{ item.name }}</div>
                            </v-chip>
                          </template>
                        </v-treeview>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="8" :key="activeKey">
                  <v-card
                    v-if="selectedFolder"
                    flat
                    style="
                      border-radius: 20px;
                      background-color: var(--v-toolbar-lighten1) !important;
                    "
                  >
                    <v-card-title>
                      Selected Folder: {{ selectedFolder.name }}
                      <v-spacer></v-spacer>
                      <v-chip color="blue" v-if="item && item.type">
                        {{ formatFolderType(item.type) }}
                      </v-chip>
                    </v-card-title>
                    <v-card-text
                      :style="{
                        width: '100%',
                        height: '75vh',
                        'overflow-y': 'auto',
                      }"
                    >
                      <v-row justify="center" style="height: 100%" class="mt-3">
                        <v-col
                          cols="12"
                          sm="3"
                          v-if="
                            fileList.length > 0 || completeUploads.length > 0
                          "
                        >
                          <v-list
                            nav
                            shaped
                            dense
                            style="max-height: 80vh; overflow-y: auto"
                            :key="documentKey"
                            subheader
                          >
                            <v-divider></v-divider>
                            <v-subheader style="font-size: 16px"
                              >Uploads</v-subheader
                            >
                            <v-divider></v-divider>
                            <v-list-item
                              v-for="(file, i) in fileList"
                              :key="file.id"
                            >
                              <v-list-item-action>
                                <v-img
                                  style="width: 30px; height: 30px"
                                  :src="getIcon(file.file.name)"
                                >
                                </v-img>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ file.file.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ file.file.type }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action :key="file.progress">
                                <v-btn
                                  v-if="!file.progress || file.progress == 0"
                                  icon
                                  color="red"
                                  @click="fileList.splice(i, 1)"
                                >
                                  <v-icon>close</v-icon>
                                </v-btn>
                                <span v-else-if="file.progress < 100">
                                  {{ file.progress }}%
                                </span>
                                <span v-else-if="file.progress == 100">
                                  <v-icon color="green" class="mr-1"
                                    >check</v-icon
                                  >
                                </span>
                              </v-list-item-action>
                            </v-list-item>
                            <v-list-item
                              v-for="(file, i) in completeUploads"
                              :key="i"
                            >
                              <v-list-item-action>
                                <v-img
                                  style="width: 30px; height: 30px"
                                  :src="getIcon(file.name)"
                                >
                                </v-img>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ file.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ file.type }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>

                        <v-col
                          cols="12"
                          :sm="
                            fileList.length > 0 || completeUploads.length > 0
                              ? 9
                              : 12
                          "
                          class="text-center"
                        >
                          <v-card
                            flat
                            @click="addFiles()"
                            style="height: 70vh; border: 1px dotted grey"
                          >
                            <v-card-text
                              class="text-center"
                              style="height: 100%"
                            >
                              <v-container fill-height>
                                <v-row align="center">
                                  <v-col cols="12" class="text-center">
                                    <v-icon x-large class="mb-1 pb-1"
                                      >cloud_upload</v-icon
                                    >
                                    <h1>Drag files or click to upload files</h1>
                                    <file-upload
                                      class="my-0 py-0"
                                      post-action=""
                                      :multiple="true"
                                      :drop="true"
                                      :drop-directory="true"
                                      v-model="fileList"
                                      ref="uploader"
                                    >
                                    </file-upload>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </div>
    </div>
    <!-- <v-card flat style="background-color: var(--v-background-base) !important">
            <v-toolbar color="greyBase" dark :extended="!$vuetify.breakpoint.mobile" flat extension-height="55">
                <v-toolbar-title>
                    <b class="mr-3">Stock Management: Upload</b>
                </v-toolbar-title>
            </v-toolbar>

            <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
                'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
                'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px'}">
                <v-card-text :style="{
                    width: '100%',
                    height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                    'overflow-y': 'auto',
                }">  <v-row justify="center" style="height: 100%" class="mt-3">
                        <v-c
                  ol cols="12" sm="3" v-if="fileList.length>0 || completeUploads.length>0">
                            <v-list nav shaped dense style="max-height: 80vh;overflow-y: auto" :key="documentKey" subheader>
                                <v-divider></v-divider>
                                <v-subheader style="font-size: 16px">Uploads</v-subheader>
                                <v-divider></v-divider>
                                <v-list-item v-for="(file, i) in fileList" :key="file.id">
                                    <v-list-item-action>
                                        <v-img style="width: 30px; height: 30px" :src="getIcon(file.file.name)">
                                        </v-img>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ file.file.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ file.file.type }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action :key="file.progress">
                                        <v-btn v-if="!file.progress || file.progress == 0" icon color="red"
                                            @click="fileList.splice(i, 1)">
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                        <span v-else-if="file.progress < 100">
                                            {{ file.progress }}%
                                        </span>
                                        <span v-else-if="file.progress == 100">
                                            <v-icon color="green" class="mr-1">check</v-icon>
                                        </span>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-for="(file, i) in completeUploads" :key="i">
                                    <v-list-item-action>
                                        <v-img style="width: 30px; height: 30px" :src="getIcon(file.name)">
                                        </v-img>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ file.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ file.type }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>

                        <v-col cols="12" :sm="fileList.length>0 || completeUploads.length>0?9:12" class="text-center">
                            <v-card flat @click="addFiles()" style="height: 80vh; border: 1px dotted grey">
                            <v-card-text class="text-center" style="height: 100%">
                                <v-container fill-height>
                                    <v-row align="center">
                                        <v-col cols="12" class="text-center">
                                            <v-icon x-large class="mb-1 pb-1">cloud_upload</v-icon>
                            <h1>Drag files or click to upload files</h1>
                            <file-upload class="my-0 py-0" post-action="" :multiple="true" :drop="true"
                                :drop-directory="true" v-model="fileList" ref="uploader">
                            </file-upload>
                                        </v-col>
                                    </v-row>
                                </v-container>
                               
                            </v-card-text>
                            </v-card>
                          
                        </v-col>
                    </v-row>
        </v-card-text>
        </v-card>
        </v-card> -->
  </div>
</template>

<script>
import axios from "axios";
import FileUpload from "vue-upload-component";

export default {
  components: {
    FileUpload,
  },
  data: () => ({
    active: [],
    activeKey: 100,
    completeUploads: [],
    directoryListing: [],
    documentKey: 1000,
    fileList: [],
    item: {},
    lastIndex: 0,
    loading: false,
    loadingFolderConfig: false,
    open: [],
    // selectedFolder: {}
  }),
  watch: {
    async active(val) {
      this.activeKey++;
      if (val.length > 0) {
        console.log("Active val", val);
        await this.getFolderConfig(val.at(-1));
      }
    },
    fileList: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.submitFiles();
        }
      },
    },
  },
  computed: {
    selectedFolder() {
      if (this.active.length == 0) return;
      return this.active.at(-1);
    },
  },
  created() {
    this.listObjects();
  },
  methods: {
    addFiles() {
      this.$refs.uploader.$el.children[0].click();
    },
    formatFolderType(type) {
      switch (type) {
        case "PALTRACK":
          return "Paltrack";
        case "TEMPLATE":
          return "Mapping Template";
        case "OCR_DOCUMENT":
          return "Document OCR";
        case "OCR_FINANCIAL":
          return "Financial OCR";
      }
    },
    getIcon(name) {
      if (name) {
        let type = name.split(".");
        let result = null;
        if (type.length > 0) {
          result = type.at(-1).toLowerCase();
        }
        if (result && result.toLowerCase() == "xlsx") {
          result = "xls";
        }
        return `/icons/${result}.svg`;
      } else {
        return;
      }
    },
    async submitFiles() {
      let pending = this.fileList.filter((x) => !x.loading);
      for (let i = 0; i < pending.length; i++) {
        pending[i].loading = true;
        let url = await URL.createObjectURL(pending[i].file);
        pending[i].fileIndex = this.lastIndex;
        this.toDataUrl(url, (data) => {
          let obj = {
            name: pending[i].name,
            data: data,
            type: pending[i].type,
            fileIndex: pending[i].fileIndex,
          };
          this.submitUpload(obj);
        });
        this.lastIndex++;
      }
    },
    async submitUpload(obj) {
      obj.path = this.selectedFolder ? this.selectedFolder.fullPath : "";
      let Api = axios.create({
        baseURL:
          process.env.NODE_ENV == "production"
            ? "https://api.loglive.ai"
            : process.env.NODE_ENV == "staging"
            ? "https://api.loglive.ai"
            : "http://localhost:3000",
        headers: {
          Authorization: `${this.$store.state.token}`,
          apikey: `${this.$store.state.orgToken}`,
        },
      });
      let result = await Api.post("/stock/upload", obj, {
        onUploadProgress: (progressEvent) => {
          let find = this.fileList.findIndex(
            (x) => x.fileIndex == obj.fileIndex
          );
          if (find > -1) {
            this.fileList[find].progress = Math.ceil(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          }
        },
      });
      result = result.data;
      this.completeUploads.push(result);
      // this.fileList = [];
      let find = this.fileList.findIndex((x) => x.fileIndex == obj.fileIndex);
      this.fileList.splice(find, 1);
      this.$message.success("Successfully uploaded " + obj.name + "!");
      this.documentKey++;
    },
    async getFolderConfig(data) {
      this.loadingFolderConfig = true;
      this.item = {};
      if (this.folderSearchQuery) {
        this.folderSearchQuery.abort();
      }
      this.folderSearchQuery = new AbortController();
      const signal = this.folderSearchQuery.signal;
      let result = await this.$API.getFTPFolderConfig(data.fullPath, signal);
      if (result) {
        this.item = result;
      }
      this.loadingFolderConfig = false;
    },
    async getSubfolders(data) {
      let result = await this.$API.getFTPRoutes({
        params: { folder: data.fullPath },
      });
      data.children = result;
    },
    async listObjects() {
      this.loading = true;
      this.directoryListing = await this.$API.getFTPRoutes({});
      this.active = [this.directoryListing[0]];
      this.loading = false;
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
  },
};
</script>