var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"pa-0 ma-0"},[_c('v-card',{staticClass:"pa-0 ma-0",staticStyle:{"background-color":"var(--v-background-base) !important"},attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"pa-0 ma-0",attrs:{"color":"greyBase","dark":"","extended":!_vm.$vuetify.breakpoint.mobile,"flat":"","extension-height":"55"}},[_c('v-toolbar-title',[_c('b',{staticClass:"mr-3"},[_vm._v("Stock File Upload")])])],1),_c('v-card',{staticClass:"mx-auto",style:({
            'margin-top': !_vm.$vuetify.breakpoint.mobile ? '-64px' : '0px',
            'border-radius': !_vm.$vuetify.breakpoint.mobile ? '50px' : '0px',
          }),attrs:{"max-width":!_vm.$vuetify.breakpoint.mobile ? '94vw' : '100%'}},[_c('v-card-text',{style:({
              height: !_vm.$vuetify.breakpoint.mobile ? '90vh' : '90vh',
              'overflow-y': 'auto',
            })},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-card',{staticStyle:{"border-radius":"20px","background-color":"var(--v-toolbar-lighten1) !important"},attrs:{"flat":"","loading":_vm.loading}},[_c('v-card-text',[_c('v-subheader',[_vm._v("Folders "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.listObjects()}}},[_c('v-icon',[_vm._v("refresh")])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},[_c('v-treeview',{attrs:{"active":_vm.active,"items":_vm.directoryListing,"load-children":_vm.getSubfolders,"open":_vm.open,"hoverable":"","activatable":"","color":"blue","item-key":"id","transition":"","return-object":""},on:{"update:active":function($event){_vm.active=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function({ item }){return [(item.children && item.children.length > 0)?_c('v-icon',{attrs:{"color":"amber"}},[_vm._v(" folder ")]):_vm._e()]}},{key:"label",fn:function({ item }){return [_c('v-chip',{on:{"contextmenu":function($event){$event.preventDefault();return _vm.viewMenu($event, item)}}},[_c('div',[_vm._v(_vm._s(item.name))])])]}}])})],1)],1)],1)],1),_c('v-col',{key:_vm.activeKey,attrs:{"cols":"12","sm":"8"}},[(_vm.selectedFolder)?_c('v-card',{staticStyle:{"border-radius":"20px","background-color":"var(--v-toolbar-lighten1) !important"},attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Selected Folder: "+_vm._s(_vm.selectedFolder.name)+" "),_c('v-spacer'),(_vm.item && _vm.item.type)?_c('v-chip',{attrs:{"color":"blue"}},[_vm._v(" "+_vm._s(_vm.formatFolderType(_vm.item.type))+" ")]):_vm._e()],1),_c('v-card-text',{style:({
                      width: '100%',
                      height: '75vh',
                      'overflow-y': 'auto',
                    })},[_c('v-row',{staticClass:"mt-3",staticStyle:{"height":"100%"},attrs:{"justify":"center"}},[(
                          _vm.fileList.length > 0 || _vm.completeUploads.length > 0
                        )?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-list',{key:_vm.documentKey,staticStyle:{"max-height":"80vh","overflow-y":"auto"},attrs:{"nav":"","shaped":"","dense":"","subheader":""}},[_c('v-divider'),_c('v-subheader',{staticStyle:{"font-size":"16px"}},[_vm._v("Uploads")]),_c('v-divider'),_vm._l((_vm.fileList),function(file,i){return _c('v-list-item',{key:file.id},[_c('v-list-item-action',[_c('v-img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":_vm.getIcon(file.file.name)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(file.file.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(file.file.type)+" ")])],1),_c('v-list-item-action',{key:file.progress},[(!file.progress || file.progress == 0)?_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.fileList.splice(i, 1)}}},[_c('v-icon',[_vm._v("close")])],1):(file.progress < 100)?_c('span',[_vm._v(" "+_vm._s(file.progress)+"% ")]):(file.progress == 100)?_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green"}},[_vm._v("check")])],1):_vm._e()],1)],1)}),_vm._l((_vm.completeUploads),function(file,i){return _c('v-list-item',{key:i},[_c('v-list-item-action',[_c('v-img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":_vm.getIcon(file.name)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(file.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(file.type)+" ")])],1)],1)})],2)],1):_vm._e(),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":_vm.fileList.length > 0 || _vm.completeUploads.length > 0
                            ? 9
                            : 12}},[_c('v-card',{staticStyle:{"height":"70vh","border":"1px dotted grey"},attrs:{"flat":""},on:{"click":function($event){return _vm.addFiles()}}},[_c('v-card-text',{staticClass:"text-center",staticStyle:{"height":"100%"}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"mb-1 pb-1",attrs:{"x-large":""}},[_vm._v("cloud_upload")]),_c('h1',[_vm._v("Drag files or click to upload files")]),_c('file-upload',{ref:"uploader",staticClass:"my-0 py-0",attrs:{"post-action":"","multiple":true,"drop":true,"drop-directory":true},model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}})],1)],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }